<template>
    <div class="body">
        <van-swipe class="my-swipe" :autoplay="3000" :height="200" indicator-color="white">
            <van-swipe-item v-for="(item, index) in images" :key="index" class="swipe-img">
                <img :src="item"/>
            </van-swipe-item>
        </van-swipe>
        
    </div>
</template>

<script>
    import Footer from "@/components/Footer";

    export default {
        data() {
            return {
                isCorpWX:false,
                isWX:false,
                user: JSON.parse(localStorage.userInfo),
                unreadNum:0,
                images: [
                    // require('../../assets/img/index/banner_1.png'),
                    require('../../assets/img/index/banner4.jpeg'),
                    // require('../../assets/img/index/banner_3.png'),
                ],
                routers: [
                    {
                        name: '查看日报',
                        url: '/calendar',
                        icon: 'description'
                    },
                    
                    {
                        name: '填写日报',
                        url: '/edit',
                        icon: 'edit'
                    },
                    
                    
                ],
            };
        },
        created() {
            let index = this.active + 1;
            this.list = this[`list${index}`]; // this.list1
        },
        mounted() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("wxwork") > 0) {
                this.isCorpWX = true;
            } else if (ua.indexOf("micromessenger") > 0) {
                this.isWX = true;
            }
            this.test();
        },
        components: {
            Footer
        },
        methods: {
            //获取账户信息
            test() {
                var array = ["111", "222", "333"];
                var ret = array.splice(1,1);
                alert(ret);
                // this.$axios.get('/user/loginByUserId', {params:{userId: this.user.id}})
                //             .then(res => {
                //                 console.log(res);
                //                 if (res.code == 'error') {
                //                     localStorage.errorMsg = res.msg;
                //                     //账号信息有问题，不能使用。
                //                     this.$router.push("/error");
                //                 } else {
                                    
                //                     localStorage.userInfo = JSON.stringify(res.data);
                //                     this.user = res.data;
                //                 } 
                //             }).catch(err=> {
                //                 alert('err=' + err);
                //             });
            },
            
        },
        activated(){
            
        }
    };
</script>

<style lang="less" scoped>
    .swipe-img {
        img {
            width: 100%;
            height: 100%;
        }
    }

    .body {
        height: calc(100vh - 50px);
        position: relative;
    }

    .tip {
        position: absolute;
        width: 100%;
        bottom: 5px;
        font-size: 14px;
        color: #8f8f8f;
        margin-top: 20px;
        text-align: center;
        line-height: 20px;
    }
</style>
